











import Vue from "vue";
import { appStore } from "@/app.store";
import {
  DocumentDto,
  getDocument,
} from "@/service/documents/documents.service";
import Section from "@/components/Section.vue";

export default Vue.extend({
  components: {
    Section,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      document: {} as DocumentDto,
    };
  },
  computed: {
    documentUrl(): string {
      return `/api/documents/${this.document.id}/download`;
    },
  },
  async mounted() {
    try {
      this.loading = true;
      this.document = await getDocument(this.id);
      this.loading = false;
    } catch (error) {
      appStore.snackbar(this.$t("shared.genericError").toString(), "error");
    }
  },
});
