










import Vue from "vue";
import { MenuAction, MenuActions } from "@/components/menu-actions";
import Section from "@/components/Section.vue";

export default Vue.extend({
  components: {
    MenuActions,
    Section,
  },
  data: () => ({
    actions: [] as Array<MenuAction>,
    loading: false,
  }),
  mounted() {
    this.populateActions();
  },
  methods: {
    populateActions() {
      if (this.$can("create", "Document"))
        this.actions.push({
          text: this.$t("documentsModule.addDocuments").toString(),
          clickHandler: this.addDocuments,
        });
    },
    addDocuments() {
      this.$router.replace({ path: "/documents/create" });
    }
  },
});
