




























































import Vue from "vue";
import Widget from "@/components/Widget.vue";
import { DocumentsAnalysisDto, getDocumentsAnalysisOf } from "@/service/documents/documents.service";
import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";

export default Vue.extend({
  components: {
    Widget
  },
  data() {
    return {
      overview:{
        count: 0,
        pendingCount: 0,
        signedCount: 0,
      } as DocumentsAnalysisDto,
      loading: true,
    };
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;
        this.overview = await getDocumentsAnalysisOf({
            entity: "company",
            id: companiesStore.state.currentId,
          });
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    }
  },
  })
