import { PageQuery } from "@/service";
import { DataOptions } from "vuetify";
import { DocumentType } from "@/service/documents/documents.service.dto";
import { i18n } from "@/plugins/i18n"

export function convertDataOptionsToPageQuery(options: DataOptions): PageQuery {
  const result: PageQuery = {
    page: options.page,
    size: options.itemsPerPage
  };

  if (options.sortBy) {
    result.sorting = options.sortBy.map((item, index) => ({ field: item, asc: !options.sortDesc[index] }));
  }

  return result;
}

const mimeToIconMap: Record<string, string> = {
  "application/pdf": "file-pdf-box",
}

const mimeToTextMap: Record<string, string> = {
  "application/pdf": "Adobe PDF",
}

const documentTypeToMap: Record<DocumentType, string> = {
  "Resolution": i18n.t("docType.resolution").toString(),
  "OptionAgreement": i18n.t("docType.optionAgreement").toString(),
  "Shares": i18n.t("docType.share").toString(),
  "SharesCertificate": i18n.t("docType.certificate").toString(),
  "Other": i18n.t("docType.other").toString(),
  "CompanyPolicy": i18n.t("docType.companyPolicy").toString(),
  "EmploymentContracts": i18n.t("docType.employmentContracts").toString(),
  "SharesAgreement": i18n.t("docType.sharesAgreement").toString(),
  "ArticlesofAssociation": i18n.t("docType.articlesofAssociation").toString(),
}

export function mimeToIcon(mime: string): string {
  return mimeToIconMap[mime];
}

export function mimeToText(mime: string): string {
  return mimeToTextMap[mime];
}

export function documentTypeToText(documentType: DocumentType): string {
  return documentTypeToMap[documentType];
}