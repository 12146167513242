






















































import Vue, { PropType } from "vue";

import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import { Filter, getDocumentsOf, uploadForCompany } from "@/service/documents/documents.service";
import { DocumentDto, DocumentType } from "@/service/documents/documents.service.dto";
import { documentTypeToText } from "@/view";

export default Vue.extend({
  props: {
    filter: {
      type: Object as PropType<Filter>,
      required: false,
      default: undefined,
    }
  },
  data() {
    return {
      documentType: undefined as DocumentType | undefined,
      documentTypeFilterItems: [
        { value: '', text: '-' },
        { value: 'Resolution', text: documentTypeToText('Resolution') },
        { value: 'OptionAgreement', text: documentTypeToText('OptionAgreement') },
        { value: 'Share', text: documentTypeToText('Shares') },
        { value: 'Certificate', text: documentTypeToText('SharesCertificate') },
        { value: 'CompanyPolicy', text: documentTypeToText('CompanyPolicy') },
        { value: 'EmploymentContracts', text: documentTypeToText('EmploymentContracts') },
        { value: 'SharesAgreement', text: documentTypeToText('SharesAgreement') },
        { value: 'ArticlesofAssociation', text: documentTypeToText('ArticlesofAssociation') },
        { value: 'Other', text: documentTypeToText('Other') },
      ],
      file: undefined as File | undefined,
      documents: [] as Array<DocumentDto>,
      loading: false,
      documentTypeFilter: "" as DocumentType | ""
    };
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;
        this.documents = await getDocumentsOf(
          this.filter || {
            entity: "company",
            id: companiesStore.state.currentId,
          }
        );
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    async create() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;
        console.log(this.documentType)
        await uploadForCompany(
          companiesStore.state.currentId,
          this.documentType??"",
          this.file
        );

        appStore.snackbar(this.$t("created").toString(), "success");
        this.$router.replace({ path: "/documents" });
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      } finally {
        this.loading = false;
      }
    },
  },
});
