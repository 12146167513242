

























































import { appStore } from "@/app.store";
import { companiesStore } from "@/service/companies/companies.store";
import {
  DocumentDto,
  getDocumentsOf,
  Filter,
  DocumentsAnalysisDto
} from "@/service/documents/documents.service";
import { mimeToIcon, mimeToText, documentTypeToText } from "@/view/helpers";
import { MenuActions, MenuActionFor } from "@/components/menu-actions";
import Grid from "@/components/grid/Grid.vue";

import Vue, { PropType } from "vue";

type DFilter = "signed" | "pending"

const stateFilter = (filter: DFilter) => (item: DocumentDto): boolean => {
  switch (filter) {
    case "signed":
      return item.status == "signed";
    case "pending":
      return item.status == "pending";
  }
}

const COUNT_PROP: Record<DFilter, keyof Pick<DocumentsAnalysisDto, "count"|"pendingCount"|"signedCount" >> = {
  signed: "signedCount",
  pending: "pendingCount"
}

export default Vue.extend({
  components: {
    MenuActions,
    Grid,
  },
  props: {
    filter: {
      type: Object as PropType<Filter>,
      required: false,
      default: undefined,
    },
    dfilter: {
      type: String as PropType<DFilter>,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$t("name").toString(),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("type").toString(),
          align: "start",
          sortable: false,
          value: "type",
        },
        {
          text: this.$t("mimeType").toString(),
          align: "start",
          sortable: false,
          value: "mimeType",
        },
        {
          text: this.$t("created").toString(),
          align: "start",
          sortable: false,
          value: "createdDate",
        },
        {
          text: "",
          align: "end",
          value: "actions",
          sortable: false,
        },
      ],
      items: [] as Array<DocumentDto>,
      actions: [] as Array<MenuActionFor<DocumentDto>>,
      documentTypeFilterItems: [
        { value: '', text: '-' },
        { value: 'Resolution', text: documentTypeToText('Resolution') },
        { value: 'OptionAgreement', text: documentTypeToText('OptionAgreement') },
        { value: 'Shares', text: documentTypeToText('Shares') },
        { value: 'SharesCertificate', text: documentTypeToText('SharesCertificate') },
        { value: 'CompanyPolicy', text: documentTypeToText('CompanyPolicy') },
        { value: 'EmploymentContracts', text: documentTypeToText('EmploymentContracts') },
        { value: 'SharesAgreement', text: documentTypeToText('SharesAgreement') },
        { value: 'ArticlesofAssociation', text: documentTypeToText('ArticlesofAssociation') },
        { value: 'Other', text: documentTypeToText('Other') },
      ],
      documentTypeFilter: "" as DocumentType | ""
    };
  },
  watch: {
    filter() {
      this.fetchData();
    },
    documentTypeFilter() {
      this.fetchData();
    }
  },
  mounted() {
    this.populateActions();
    this.fetchData();
  },
  methods: {
    mimeToIcon,
    mimeToText,
    documentTypeToText,
    async fetchData() {
      if (!companiesStore.state.currentId) return;

      try {
        this.loading = true;
        var result = await getDocumentsOf(
          this.filter || {
            entity: "company",
            id: companiesStore.state.currentId,
          },
          this.documentTypeFilter == "" ? undefined : this.documentTypeFilter
        );
        if(this.dfilter != undefined){
          result = result.filter(stateFilter(this.dfilter))
        }
        this.items = result;
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    async onDelete(item: DocumentDto) {
      try {
        this.loading = true;
        //await deleteStakeholder(item.id);
        const index = this.items.findIndex((x) => x.id == item.id);
        this.items.slice(index, 1);
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
    sendDocument(document: DocumentDto) {
      //TODO:
    },
    populateActions() {
      // if (this.$can("update", "Document"))
      //   this.actions.push({
      //     text: this.$t("send").toString(),
      //     clickHandler: this.sendDocument,
      //   });
    },
    getCountByStatus(item: DocumentsAnalysisDto): string {
      let count = item[COUNT_PROP[this.dfilter]];
      return this.$n(count).toString();
    },
  },
});
