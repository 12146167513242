var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-5"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('Grid',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","lg":"4","sm":"6"}},[_c('v-select',{staticClass:"pt-0 mr-2",attrs:{"items":_vm.documentTypeFilterItems,"label":_vm.$t('type')},model:{value:(_vm.documentTypeFilter),callback:function ($$v) {_vm.documentTypeFilter=$$v},expression:"documentTypeFilter"}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'view-document', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(item.createdDate))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.documentTypeToText(item.type))+" ")]}},{key:"item.mimeType",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"title":_vm.mimeToText(item.mimeType)}},[_vm._v(" mdi-"+_vm._s(_vm.mimeToIcon(item.mimeType))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('MenuActions',{attrs:{"actions":_vm.actions,"context":item,"inline":""}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }