









import Vue from "vue";
import ViewDocumentsList from "./ViewDocumentsList.vue"

export default Vue.extend({
    components:{
    ViewDocumentsList
  }
});
